<template>
  <section>
    <v-card>
      <v-card-text>
        <table class="info-table">
          <template v-for="(row, i) in summary">
            <tr :key="i">
              <th>
                <div class="content-title">
                  <span v-if="i == 0">Suggested Route</span>
                  <span v-else> {{ `Vehicle-${i}` }}</span>
                </div>
              </th>
            </tr>
            <tr :key="i">
              <td>
                <span class="tag">
                  <label for="">{{ $t("name") }}</label>
                  <v-chip class="chip-w-lg">{{ row.name }}</v-chip>
                </span>
              </td>

              <td>
                <span class="tag">
                  <label for="">{{ $t("distance_") }}</label>
                  <v-chip class="chip-w-md">{{ row.distance }}</v-chip>
                </span>
              </td>
              <td>
                <span class="tag">
                  <label for="">{{ $t("cost") }}</label>
                  <v-chip>{{ row.cost }}</v-chip>
                </span>
              </td>
              <td>
                <span class="tag">
                  <label for="">{{ $t("time") }}</label>
                  <v-chip>{{ row.time }}</v-chip>
                </span>
              </td>
            </tr>
          </template>
        </table>
        <!-- <div
          v-for="(row, i) in summary"
          :key="i"
          class="wrapper"
        >
          <div class="content-title">
            <span v-if="i == 0">Suggested Route</span>
            <span v-else> {{ `Vehicle-${i}` }}</span>
          </div>
          <div class="content-wrapper">
            <span class="tag">
              <label for="">{{ $t("name") }}</label>
              <v-chip class="chip-w-lg">{{ row.name }}</v-chip>
            </span>
            <span class="tag">
              <label for="">{{ $t("distance_") }}</label>
              <v-chip class="chip-w-md">{{ row.distance }}</v-chip>
            </span>
            <span class="tag">
              <label for="">{{ $t("cost") }}</label>
              <v-chip>{{ row.cost }}</v-chip>
            </span>
            <span class="tag">
              <label for="">{{ $t("time") }}</label>
              <v-chip>{{ row.time }}</v-chip>
            </span>
          </div>
        </div> -->
      </v-card-text>
    </v-card>
  </section>
</template>
<script>
export default {
  props: {
    items: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    summary() {
      const arr = [];
      if (this.items?.routes?.id) {
        const suggested_route = {
          name: this.items?.routes?.name,
          distance: this.items?.routes?.distance,
          cost: this.items?.routes?.cost,
          time: this.items?.routes?.time,
        };
        arr.push(suggested_route);
        if (this.items?.vehicles.length > 0) {
          const veh = this.items.vehicles;
          veh.forEach((el) => {
            arr.push({
              name: el.vehicle_data.name,
              distance: el.vehicle_data.distance,
              cost: el.vehicle_data.cost,
              time: el.vehicle_data.time,
            });
          });
        }
      }

      return arr;
    },
  },
};
</script>
<style lang="sass" scoped>

.info-table
  width: 80%
  tr
    th
      text-align: left

    td
      width: 8%
      &:first-child, &:nth-child(2)
        width: 10%


.tag
  label
    font-size: 12px
    color: grey
    padding-right: 0.5rem

@media only screen and (max-width: 960px)
    .info-table
        width: 100%
        tr
          display: flex
          flex-direction: column
          td
            width: 100%
            &:first-child, &:nth-child(2)
              width: 100%
            .tag
              display: flex
              justify-content: space-between
              align-items: center
              margin: 0.5rem 0px
</style>
